import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'tools', loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //enableTracing: true, // <-- debugging purposes only
    //scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
